import React, { FormEvent, useEffect, useState } from 'react';
import { Alert, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap';
import { CustomModal } from '../shared/CustomModal';
import { ApiClient } from '../services/api/ApiClient';
import { Forms } from './Forms';
import { ITreatment } from '../types/models/Treatments';

type Props = {
  treatment_id?: number | null;
  onClose: () => void;
};

type TreatmentProps = Omit<ITreatment, 'id'> | ITreatment | null;

export const Treatment: React.FC<Props> = ({ treatment_id, onClose }) => {
  const [treatment, setTreatment] = useState<TreatmentProps>(null);
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (treatment_id) {
      ApiClient.treatments.show(treatment_id).then((response) => {
        setTreatment(response);
      });
    } else {
      setTreatment({
        name: '',
        base_price: 0
      });
    }
  }, [treatment_id]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (treatment) {
      setTreatment({ ...treatment, [target.name]: target.value });
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors([]);
    if (treatment) {
      if ((treatment as ITreatment).id) {
        // Edit mode (update)
        ApiClient.treatments.update(treatment as ITreatment).then(() => {
          onClose();
        }).catch(err => {
          setErrors(err.response.data);
        });
      } else {
        // Create mode
        ApiClient.treatments.create(treatment).then(() => {
          onClose();
        }).catch(err => {
          setErrors(err.response.data);
        });
      }
    }
  };

  return (
    <CustomModal onClose={onClose} title={treatment && (treatment as ITreatment).id ? "Editar Facturable" : "Nuevo Facturable"}>
      {errors.length > 0 && <Forms.Errors errors={errors} />}
      <Form onSubmit={handleSubmit} id='new-form'>
        <FormGroup>
          <FormLabel>Nombre</FormLabel>
          <FormControl
            size='sm'
            className='rounded-0'
            type='text'
            name='name'
            value={treatment?.name}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup className='mt-2'>
          <FormLabel>Precio Base</FormLabel>
          <FormControl
            type='number'
            size='sm'
            className='rounded-0'
            name='base_price'
            value={treatment?.base_price}
            onChange={handleChange}
          />
        </FormGroup>
      </Form>
    </CustomModal>
  );
};