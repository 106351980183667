import React, { FormEvent, useEffect, useState } from 'react'
import { IPatientForm } from '../types/models/Patient'
import { ButtonGroup, Form, FormControl, FormGroup, FormLabel, InputGroup, ToggleButton } from 'react-bootstrap'
import { CustomModal } from '../shared/CustomModal'
import { ApiClient } from '../services/api/ApiClient'
import { Forms } from './Forms'
import { IDoctor, IDoctorForm } from '../types/models/Doctor'
import { Factory } from '../utils/Factories'
import { ITreatment } from '../types/models/Treatments'

type Props = {
  doctor_id: number | null
  onClose: () => void
}

export const Doctor: React.FC<Props> = ({ doctor_id, onClose }) => {
  const [doctor, setDoctor] = useState<IDoctorForm | null>(null);
  const [errors, setErrors] = useState<string[]>([]);


  useEffect(() => {
    if (doctor_id) {
      ApiClient.doctors.show(doctor_id).then((response) => {
        setDoctor(response);
      });
    }
    else {
      setDoctor(Factory.doctor)
    }
  }, [doctor_id]);

  const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (doctor) {
      setDoctor({ ...doctor, [target.name]: target.value });
    }
  };

  const handleBooleanChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (doctor) {
      setDoctor({ ...doctor, [target.name]: parseInt(target.value) === 0 });
    }
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors([]);
    if (isFormValid()) {
      if (doctor) {
        if ((doctor as IDoctor).id) {
          // Edit mode (update)
          ApiClient.doctors.update(doctor as IDoctor).then(() => {
            onClose();
          }).catch(err => {
            console.log(err.response.data)
            setErrors(err.response.data);
          });
        } else {
          // Create mode
          ApiClient.doctors.create(doctor).then(() => {
            onClose();
          }).catch(err => {
            setErrors(err.response.data);
          });
        }
      }
    }
    else {
      setErrors(["Las contraseñas no coinciden"])
    }

  };

  const isFormValid = () => {
    if (doctor) {
      return doctor.password !== "" && (doctor.password === doctor.password_confirmation)
    }
    return false
  }


  return (
    <CustomModal onClose={onClose} title={doctor && (doctor as IDoctor).id ? "Editar Doctor" : "Nuevo Doctor"}>
      {errors.length > 0 && <Forms.Errors errors={errors} />}

      <Form onSubmit={handleSubmit} id='new-form'>
        <FormGroup>
          <FormLabel>Nombre</FormLabel>
          <FormControl
            size='sm'
            className='rounded-0'
            type='text'
            name='name'
            value={doctor?.name || ""}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup className='mt-2'>
          <FormLabel>Email</FormLabel>
          <FormControl
            type='email'
            size='sm'
            className='rounded-0'
            name='email'
            value={doctor?.email || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>C.I.</FormLabel>
          <FormControl

            type='number'
            size='sm'
            className='rounded-0'
            name='ci'
            value={doctor?.ci || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Usuario</FormLabel>
          <FormControl
            type='text'
            size='sm'
            className='rounded-0'
            name='username'
            value={doctor?.username || ""}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Horario</FormLabel>
          <InputGroup>
            <FormControl
              type='time'
              size='sm'
              className='rounded-0'
              name='entry_time'
              value={doctor?.entry_time || ""}

              onChange={handleChange}
            />
            <FormControl
              type='time'
              size='sm'
              className='rounded-0'
              name='exit_time'
              value={doctor?.exit_time || ""}
              onChange={handleChange}
            />
          </InputGroup>

        </FormGroup>
        {
          !doctor?.id && (
            <>
              <FormGroup className='mt-2'>
                <FormLabel>Contraseña</FormLabel>
                <FormControl
                  type='password'
                  size='sm'
                  className='rounded-0'
                  name='password'
                  value={doctor?.password || ""}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className='mt-2'>
                <FormLabel>Confirmar Contraseña</FormLabel>
                <FormControl
                  type='password'
                  size='sm'
                  className='rounded-0'
                  name='password_confirmation'
                  value={doctor?.password_confirmation || ""}
                  onChange={handleChange}
                />
              </FormGroup>
            </>
          )
        }


        <FormGroup className='mt-2'>
          <FormLabel>Activo?</FormLabel>
          <ButtonGroup className='w-100'>
            <ToggleButton

              id="toggle-true-banned"
              type="radio"
              name='active'
              variant={doctor?.active ? "outline-secondary" : "secondary"}
              checked={doctor?.active}
              value="1"
              onChange={handleBooleanChange}
            >
              SI
            </ToggleButton>
            <ToggleButton
              id="toggle-false-banned"
              type="radio"
              name='active'
              variant={doctor?.active ? "outline-secondary" : "secondary"}
              checked={!doctor?.active}
              value="0"
              onChange={handleBooleanChange}
            >
              NO
            </ToggleButton>
          </ButtonGroup>

        </FormGroup>
      </Form>
    </CustomModal >
  )
}


