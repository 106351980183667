import React, { FormEvent, useState } from 'react'
import { IPatient, IPatientForm } from '../types/models/Patient'
import { Alert, Form, FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import { CustomModal } from '../shared/CustomModal'
import { ApiClient } from '../services/api/ApiClient'
import { Forms } from './Forms'

type Props = {
  patient: IPatient | null
  onClose: () => void
}

export const Patient: React.FC<Props> = ({ patient, onClose }) => {
  const [errors, setErrors] = useState<string[]>([])
  const [formData, setFormData] = useState<IPatientForm>(
    patient || { first_name: "", last_name: "", ci: "", phone: "" }
  )
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors([])
    if (formData.id) {
      ApiClient.patients.update(formData).then(() => {
        onClose();
      }).catch(err => {
        setErrors(err.response.data)
      })
    } else {
      ApiClient.patients.create(formData).then(() => {
        onClose();
      })
        .catch(err => {
          console.log(err.response.data)
          setErrors(err.response.data)
        })

    }
  }


  return (
    <CustomModal onClose={onClose} title={formData.id ? "Actualizar Paciente" : "Nuevo Paciente"}>
      {errors.length > 0 && <Forms.Errors errors={errors} />}
      <Form onSubmit={handleSubmit} id='new-form'>
        <FormGroup>
          <FormLabel>Nombre</FormLabel>
          <FormControl
            size='sm'
            className='rounded-0'
            type='text'
            name='first_name'
            value={formData?.first_name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Apellido</FormLabel>
          <FormControl
            size='sm'
            className='rounded-0'
            type='text'
            name='last_name'
            value={formData?.last_name}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>CI</FormLabel>
          <FormControl
            type='number'
            size='sm'
            className='rounded-0'

            name='ci'
            value={formData?.ci}
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Telefono</FormLabel>
          <FormControl
            type='number'
            size='sm'
            className='rounded-0'
            name='phone'
            value={formData?.phone}
            onChange={handleChange}
          />
        </FormGroup>
      </Form>
    </CustomModal>
  )
}


