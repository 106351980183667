
import { Appointment } from './Appointment'
import { Doctor } from './Doctor'
import { Errors } from './Errors'
import { Event } from './Event'
import { Patient } from './Patient'
import { Payment } from './Payment'
import { Treatment } from './Treatment'

export const Forms = {
  Patient, Event, Errors, Appointment, Payment, Treatment, Doctor
}