import moment from 'moment';
import React, { FormEvent, useState } from 'react';
import { Form, FormGroup, FormLabel, FormControl, FormSelect } from 'react-bootstrap';
import { ApiClient } from '../services/api/ApiClient';
import { CustomModal } from '../shared/CustomModal';
import { PatientSelector } from '../shared/Selectors';
import { Forms } from './Forms';
import { SlotInfo } from 'react-big-calendar';
import { IAppointmentForm, IEventForm } from '../types/models/Appointment';
import { useNavigation } from '../pages/auth/NavigationContext';

type Props = {
  event: SlotInfo | null
  onClose: () => void
}
export const Event: React.FC<Props> = ({ event, onClose }) => {
  const { doctor } = useNavigation();
  const [errors, setErrors] = useState<string[]>([])
  const [formData, setFormData] = useState<IEventForm>({
    doctor_id: doctor!.id.toString(),
    patient_id: "",
    start: moment(event?.start).format(),
    duration: 30,
    status: 0
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setErrors([])
    ApiClient.appointments.create(formData).then(() => {
      onClose()
    })
      .catch(err => {
        setErrors(err.response.data)
      })
  }

  const handleDateChange = (value: string) => {
    moment(value).format()
    setFormData({ ...formData, start: value })

  }
  const handleTimeChange = (value: string) => {
    const currentDate = formData.start ? moment(formData.start).format('YYYY-MM-DD') : '';
    let newDate = moment(`${currentDate}T${value}`).format()
    setFormData({ ...formData, start: newDate })
  }




  return (
    <CustomModal onClose={onClose} title="Nueva Cita">

      {errors.length > 0 && <Forms.Errors errors={errors} />}

      <Form id='new-form' onSubmit={handleSubmit}>

        <FormGroup>
          <FormLabel>Paciente</FormLabel>
          <PatientSelector onSelect={(v) => setFormData({ ...formData, patient_id: v.toString() })} />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Fecha</FormLabel>
          <FormControl
            type='date'
            value={moment(formData.start).format("YYYY-MM-DD")} // Only showing the time part
            onChange={(e) => handleDateChange(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Hora</FormLabel>
          <FormControl
            type='time'
            value={moment(formData.start).format("HH:mm")} // Only showing the time part
            onChange={(e) => handleTimeChange(e.target.value)}
          />
        </FormGroup>
        <FormGroup className='mt-2'>
          <FormLabel>Duracion</FormLabel>
          <FormSelect value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: parseInt(e.target.value) })}>
            <option value={30}>30 minutos</option>
            <option value={60}>1 hora</option>
            <option value={90}>1 hora y media</option>
            <option value={120}>2 horas</option>
          </FormSelect>
        </FormGroup>


      </Form>
    </CustomModal>
  )
}
