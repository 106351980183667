import React, { FormEvent, useEffect, useState } from 'react'

import { Button, ButtonGroup, Col, Form, FormControl, FormGroup, FormLabel, FormSelect, ListGroup, ListGroupItem, Row, Table } from 'react-bootstrap'
import { CustomModal } from '../shared/CustomModal'
import { ApiClient } from '../services/api/ApiClient'
import { Forms } from './Forms'
import { PatientSelector } from '../shared/Selectors'
import moment from 'moment'

import { ITreatment } from '../types/models/Treatments'
import { formatMoney } from '../utils/formatMoney'
import { IAppointmentForm } from '../types/models/Appointment'

export const calculateTotal = (billable: any) => {
  return (billable.quantity * billable.price) || 0
}

type UpdateProps = {
  appointment: any
  onClose: () => void
}


export const Appointment: React.FC<UpdateProps> = ({ appointment, onClose }) => {
  const [formData, setFormData] = useState<any>(appointment!)
  const [errors, setErrors] = useState<string[]>([])
  const [action, setAction] = useState("")
  const [billables, setBillables] = useState<any[]>([])
  const [treatments, setTreatments] = useState<ITreatment[]>([])
  const [currentBillable, setCurrentBillable] = useState<any | null>(null)
  const [comment, setComment] = useState("");

  useEffect(() => {
    ApiClient.treatments.index().then(res => {
      setTreatments(res)
    })

  }, [])

  const addBillable = () => {
    if (currentBillable) {
      setBillables([...billables, currentBillable])
      setErrors([])
    }
  }

  const removeBillable = (index: number) => {
    setBillables(billables.filter((_, i) => i !== index))
  }

  const handleChangeOnTreatment = (value: string) => {
    let treatment = treatments.find(treatment => treatment.id === parseInt(value))!
    let b = {
      treatment_id: treatment.id,
      name: treatment.name,
      quantity: currentBillable?.quantity || 1,
      price: treatment.base_price
    }

    setCurrentBillable({ ...currentBillable, ...b, })
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    let fd = formData;
    if (comment) {
      fd.comments_attributes = [{ content: comment }]
    }
    if (action === 'complete') {
      fd.billables_attributes = billables
      fd.status = 2
    }
    if (action === 'cancel') {
      fd.status = 1
    }
    ApiClient.appointments.update(fd).then(() => {
      onClose()
    }).catch(err => {
      setErrors(err.response.data)
    })
  }

  const handleDateChange = (value: string) => {
    moment(value).format('YYYY-MM-DDTHH:mm')
    setFormData({ ...formData, start: value })

  }
  const handleTimeChange = (value: string) => {
    const currentDate = formData.start ? moment(formData.start).format('YYYY-MM-DD') : '';
    let newDate = moment(`${currentDate}T${value}`).format('YYYY-MM-DDTHH:mm')
    setFormData({ ...formData, start: newDate })
  }

  return (
    <CustomModal size={action === 'complete' ? 'xl' : undefined} onClose={onClose} title={formData.id ? "Editar Cita" : "Nueva Cita"}>

      <Row>
        <Col>

          {errors.length > 0 && <Forms.Errors errors={errors} />}
        </Col>
      </Row>
      <Row>
        <Col>
          <Form id='new-form' onSubmit={handleSubmit}>
            <ListGroup className='border border-info my-2'>
              <ListGroupItem>Paciente: {formData.title} </ListGroupItem>
              <ListGroupItem>Fecha: {moment(formData.start).format("DD/MM/YYYY")}</ListGroupItem>
              <ListGroupItem>Hora: {moment(formData.start).format("HH:mm A")} - {formData.duration ? moment(formData.start).add(formData.duration,'minutes').format("HH:mm A"): moment(formData.end).format("HH:mm A")}</ListGroupItem>
            </ListGroup>
            <ButtonGroup size='sm' className='w-100'>
              <Button onClick={() => setAction("complete")} variant={action === 'cancel' ? 'success' : 'outline-success'}>Completar</Button>
              <Button onClick={() => setAction("cancel")} variant={action === 'cancel' ? 'danger' : 'outline-danger'}>Cancelar</Button>
              <Button onClick={() => setAction("reschedule")} variant={action === 'reschedule' ? 'primary' : 'outline-primary'}>Reagendar</Button>
            </ButtonGroup>
            {action === 'reschedule' && (
              <>
                <FormGroup className='mt-2'>
                  <FormLabel>Fecha</FormLabel>
                  <FormControl
                    type='date'
                    value={moment(formData.start).format("YYYY-MM-DD")} // Only showing the date part
                    onChange={(e) => {
                     handleDateChange(e.target.value)
                    }}
                  />
                </FormGroup>
                <FormGroup className='mt-2'>
                  <FormLabel>Hora</FormLabel>
                  <FormControl
                    type='time'
                    value={moment(formData.start).format("HH:mm")} // Only showing the time part
                    onChange={(e) => {
                      handleTimeChange(e.target.value)
                     }}
                  />
                </FormGroup>

                <FormGroup className='mt-2'>
                  <FormLabel>Duracion</FormLabel>
                  <FormSelect value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: parseInt(e.target.value) })}>
                    <option value="">Seleccione...</option>
                    <option value={30}>30 minutos</option>
                    <option value={60}>1 hora</option>
                    <option value={90}>1 hora y media</option>
                    <option value={120}>2 horas</option>
                  </FormSelect>
                </FormGroup>
              </>
            )}
            {action === 'cancel' && (
              <>
                <FormGroup className='mt-2'>
                  <FormLabel>Motivo:</FormLabel>
                  <FormControl
                    type='text'
                    value={comment} // Only showing the date part
                    onChange={(e) => setComment(e.target.value)}
                  />
                </FormGroup>

              </>
            )}

          </Form>
        </Col>
        {action === 'complete' && (
          <Col>
            <FormGroup className='mt-2'>
              <FormLabel>Observaciones:</FormLabel>
              <FormControl
                type='text'
                value={comment} // Only showing the date part
                onChange={(e) => setComment(e.target.value)}
              />
            </FormGroup>
            <Table size='sm'>
              <thead>
                <tr>
                  <th colSpan={999}>Servicios</th>
                </tr>
                <tr>
                  <td>

                    <FormSelect size='sm' onChange={({ target }) => handleChangeOnTreatment(target.value)}>
                      <option value="">Seleccione...</option>
                      {
                        treatments.map(treatment => (
                          <option key={treatment.id} value={treatment.id}>{treatment.name}</option>
                        ))
                      }
                    </FormSelect>

                  </td>
                  <td>
                    <FormControl size='sm' type='number' value={currentBillable?.quantity || 1} onChange={({ target }) => setCurrentBillable({ ...currentBillable, price: currentBillable.price, quantity: target.value })} />

                  </td>
                  <td>
                    <Button onClick={() => addBillable()} size='sm' variant='success'>Agregar</Button>

                  </td>
                </tr>
              </thead>
            </Table>
            {billables.length > 0 && (
              <>


                <Table size='sm'>
                  <thead>
                    <tr>
                      <th>Tratamiento</th>
                      <th>Cantidad</th>
                      <th>Precio</th>
                      <th>Total</th>
                    </tr>

                  </thead>
                  <tbody>
                    {
                      billables.map((billable, index) => (
                        <tr >
                          <td>{billable.name}</td>
                          <td>{billable.quantity}</td>
                          <td>{formatMoney(billable.price)}</td>
                          <td>{formatMoney(calculateTotal(billable))}</td>
                          <td style={{ textAlign: 'right' }}><Button size='sm' variant='danger' onClick={() => removeBillable(index)}>X</Button></td>
                        </tr>
                      ))
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td className='text-success' colSpan={4}>Total</td>
                      <td className='text-success text-end'>{formatMoney(billables.reduce((acc, billable) => acc + calculateTotal(billable), 0))}</td>
                    </tr>
                  </tfoot>
                </Table>
              </>
            )}


          </Col>

        )}
      </Row>

    </CustomModal>
  )
}